.quad-colors {
  width: 55rem;
  scroll-behavior: smooth;
  animation-name: trans;
  animation-duration: 300ms;
  max-width: 100%;
}

.quad-colors-clr1 {
  height: 5rem;
  margin-right: 1rem;
  margin-top: 0.75rem;
  border-radius: 20px;
  position: relative;
}

.quad-colors-clr2 {
  height: 5rem;
  margin-right: 1rem;
  margin-top: 0.75rem;
  border-radius: 20px;
  position: relative;
}

.quad-colors-first {
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  cursor: pointer;
}

.quad-colors-second {
  display: grid;
  grid-template-columns: 1fr 2.2fr;
  cursor: pointer;
}

.quad-colors-overlay-long {
  background-color: #f0f2f6;
  position: absolute;
  right: 0;
  height: 100%;
  border-radius: 18px;
  width: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  font-size: 2rem;
  transition: all linear 100ms;
  cursor: pointer;
}

.quad-colors-overlay-short {
  background-color: #f0f2f6;
  position: absolute;
  right: 0;
  height: 100%;
  border-radius: 18px;
  width: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-size: 2rem;
  transition: all linear 100ms;
  cursor: pointer;
}

.quad-colors-icons {
  color: #dd707c;
  transition: all linear 100ms;
}

.quad-colors-icons:hover {
  transform: scale(1.2);
}

.quad-colors-clr1:hover .quad-colors-overlay-long {
  width: 40%;
}

.quad-colors-clr2:hover .quad-colors-overlay-short {
  width: 50%;
}

.random-colors-container-foot {
  margin-top: 12rem;
}

@keyframes trans {
  from {
    transform: translateX(1000%);
  }
  to {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 850px) {
  .quad-colors-clr1 {
    height: 4rem;
    margin-right: 0.5rem;
    border-radius: 15px;
  }
  .quad-colors-clr2 {
    height: 4rem;
    margin-right: 0.5rem;
    border-radius: 15px;
  }
  .quad-colors-overlay-long {
    border-radius: 15px;
  }
  .quad-colors-overlay-short {
    border-radius: 15px;
  }
}

@media screen and (max-width: 600px) {
  .quad-colors-clr1 {
    height: 3rem;
    margin-right: 0.5rem;
    border-radius: 15px;
  }
  .quad-colors-clr2 {
    height: 3rem;
    margin-right: 0.5rem;
    border-radius: 15px;
  }

  .quad-colors-first {
    grid-template-columns: 1.5fr 1fr;
  }

  .quad-colors-second {
    grid-template-columns: 1fr 1.5fr;
  }

  .quad-colors-overlay-long {
    gap: 1.5rem;
    font-size: 1.5rem;
  }

  .quad-colors-overlay-short {
    gap: 0.75rem;
    font-size: 1.5rem;
  }
  .quad-colors-clr2:hover .quad-colors-overlay-short {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .quad-colors-overlay-long {
    gap: 1rem;
    font-size: 1.25rem;
  }
  .quad-colors-overlay-short {
    gap: 0.4rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 370px) {
  .quad-colors-overlay-long {
    gap: 0.75rem;
    font-size: 1.25rem;
  }
  .quad-colors-overlay-short {
    gap: 0.3rem;
    font-size: 1.2rem;
  }
}
