.single-saved-color {
  width: 13rem;
  height: 13rem;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}

.single-saved-color-overlay {
  position: absolute;

  background-color: #f0f2f6;
  width: 100%;
  height: 3rem;
  bottom: 0;

  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30%;
}

.single-saved-color-overlay-delete {
  font-size: 1.3rem;
  color: #dd707c;
  transition: all linear 100ms;
  cursor: pointer;
}

.single-saved-color-overlay-copy {
  font-size: 1.5rem;
  color: #dd707c;
  transition: all linear 100ms;
  cursor: pointer;
}

.single-saved-color-overlay-delete:hover {
  transform: scale(1.2);
}

.single-saved-color-overlay-copy:hover {
  font-size: 1.5rem;
  transform: scale(1.2);
}

@media screen and (max-width: 980px) {
  .single-saved-color {
    width: 10rem;
    height: 10rem;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
  }
  .single-saved-color-overlay {
    height: 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  .single-saved-color {
    width: 8rem;
    height: 8rem;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
  }
  .single-saved-color-overlay {
    height: 2rem;
  }

  .single-saved-color-overlay-delete {
    font-size: 1.1rem;
  }

  .single-saved-color-overlay-copy {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 345px) {
  .single-saved-color {
    width: 6rem;
    height: 6rem;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
  }
  .single-saved-color-overlay {
    height: 1.7rem;
  }

  .single-saved-color-overlay-delete {
    font-size: 1.1rem;
  }

  .single-saved-color-overlay-copy {
    font-size: 1.3rem;
  }
}
