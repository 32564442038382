.searchbar-form {
  width: 25rem;
  max-width: 100%;
  border: 1px #e2e3e5 solid;
  padding: 0.5rem 2rem;
  border-radius: 20px;
  display: flex;
  gap: 1rem;
}

.searchbar-icon {
  color: #dd707c;
  transform: rotateY(180deg);
  font-size: 2rem;
}

.searchbar-input {
  outline: none;
  letter-spacing: 2px;
  font-size: 1.2rem;
  width: 100%;
  border: none;
}

@media only screen and (max-width: 800px) {
  .searchbar-icon {
    font-size: 1.5rem;
  }

  .searchbar-input {
    font-size: 1rem;
  }
}
